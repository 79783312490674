<script lang="ts">
  import { leaders, updateLeaders } from "../store/leaders";
  import Avatar from "./Avatar.svelte";
  import Balance from "../uikit/Balance.svelte";
  import Flex from "./Flex.svelte";
  import Gif from "./Gif.svelte";
  import Level from "./Level.svelte";
  import * as leadersSrc from "../images/leaders";
  import { client } from "../library/fetch";
  import Badge from "../uikit/Badge.svelte";

  let friendsList = [...($leaders?.users ?? [])];
  updateLeaders();

  function getSt(i: number) {
    return `_${i + 1}st` as keyof typeof leadersSrc;
  }

  let total = $leaders?.total ?? 0;

  let limit = 10;
  let page = -1;
  let promise = loadMore();

  async function loadMore(e?: Event) {
    e?.preventDefault();

    if ((page + 1) * limit > total) return;

    await client.api.game.leaderboard
      .get({ query: { peerPage: limit, page: ++page } })
      .then(({ data }) => {
        total = data?.total ?? $leaders?.total ?? 0;
        if (!page) friendsList = [];
        friendsList = [...friendsList, ...(data?.users ?? [])];
      })
      .catch(console.error);
  }
</script>

<Flex column fullwidth gap={20}>
  <Flex column fullwidth gap={12}>
    <table>
      <tbody>
        {#each friendsList as user, i (user.id)}
          <tr>
            <td>
              {#if leadersSrc[getSt(i)]}
                <img
                  width="24px"
                  style="margin-right: 8px;"
                  src={leadersSrc[getSt(i)]}
                  alt="leader"
                />
              {:else}
                {i + 1}
              {/if}
            </td>
            <td>
              <Flex gap={20} align="center">
                <Avatar size={40} telegramId={user.telegramId} />
                <Flex column align="start" gap={6}>
                  <Flex align="center" justify="start" gap={8}>
                    <p class="nick">{user.name}</p>
<!--                    <Level-->
<!--                      size={12}-->
<!--                      icon={user.level?.current.icon ?? "wood"}-->
<!--                    />-->
                  </Flex>
                  <Flex gap={8}>
                    <Badge>
                      {user.level?.currentUserLevelIndex+1}lvl
                    </Badge>

                    <p class="count">
                      <i class="icon-friends" />
                      {user.inviteCount}
                    </p>
                  </Flex>
                </Flex>
              </Flex>
            </td>
            <td>
              <Flex align="center" justify="end" gap={4}>
                <p class="balance">
                  <Balance big balance={user.totalBalance} />
                </p>
                <Gif type="gem" size={15} />
              </Flex>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
    {#await promise}
      <p>Loading...</p>
    {:then _}
      {#if friendsList.length < total}
        <a
          on:mousedown={(e) => {
            e.preventDefault();
            promise = loadMore();
          }}
          href="#loadmore"
        >
          Load More
        </a
        >
      {/if}
    {/await}
  </Flex>
</Flex>

<style lang="sass">
  a
    font-family: Work Sans
    font-size: 15px
    font-weight: 600
    line-height: 18px
    text-align: center
    text-decoration: none
    color: var(--strong-up)

  table
    width: 100%
    border-spacing: 0

    tr td
      height: 72px
      border-bottom: 1px solid var(--container)

  .balance
    font-family: Work Sans
    font-size: 17px
    font-weight: 600
    line-height: 20.4px
    text-align: right
    color: var(--white)

  .nick
    font-family: Work Sans
    font-size: 15px
    font-weight: 600
    line-height: 19.5px
    text-align: left
    color: var(--white)

  .count
    font-family: Work Sans
    font-size: 14px
    font-weight: 500
    line-height: 16.8px
    text-align: left
    color: var(--white)

    i
      color: var(--gray-dark)
</style>
