<script lang="ts">
  import { click } from "../../../library/click";
  import { profile } from "../../../store/profile";
  import { dailyEvents } from "../../../components/DailyReward.svelte";
  import Flex from "../../../components/Flex.svelte";
  import Icon from "../../../components/Icon.svelte";
  import { tabActivate } from "../../../components/Tabs/TabsItem.svelte";
  import Test from "../../../components/Test.svelte";
  import Diamond from "../images/Diamond.svelte";

  $: oneHours = $profile!.mining.hourReward;
</script>

<Flex justify="between" gap={20}>
  <button use:click={() => tabActivate.emit("skins")} class="skin">
    <Diamond />
    VIP Skin
  </button>

  <Flex gap={12}>
    <button use:click={() => tabActivate.emit("boosts")}>
      <Icon icon="boosts" size={24} />
      Mining Boost
    </button>

    <button use:click={() => dailyEvents.emit()} class="calendar">
      <Icon icon="calendar" size={30} />
    </button>
  </Flex>
</Flex>

<style lang="sass">
  .xxl
    font-size: 20px
    font-weight: 600
    line-height: 24px
    text-align: center

  .info
    font-size: 11px
    font-weight: 500
    line-height: 13.2px
    text-align: center
    color: var(--gray-light)

  button
    border-radius: 32px
    height: 44px
    padding: 4px 6px
    width: auto
    border: 2px solid white
    background: rgba(0, 0, 0, 0.67)
    backdrop-filter: blur(9.5px)
    font-size: 15px !important
    font-weight: 600 !important
    line-height: 120% !important

  .calendar
    border-radius: 16px

  .skin
    padding: 6px 12px
    border: none
    background: linear-gradient(134deg, #FFF6C0 -11.88%, #FFFEFD 9.71%, #FF851A 25.06%, #D25304 53.82%, #883A1E 71.28%, #883223 92.9%, #CF8556 121.05%)
    box-shadow: 0px -6px 4px 0px rgba(255, 255, 255, 0.25) inset, 0px -3px 15.5px 1.714px rgba(255, 125, 45, 0.81)
</style>
