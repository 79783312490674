import "./plugins/signals";

import App from "./app.svelte";
import eruda from "eruda";

eruda.init();

new App({
  target: document.getElementById('app')!
});
