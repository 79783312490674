import * as banners from "../images/banners";
import * as gifs from "../images/gifs";
import * as icons from "../images/color-icons";
import * as invites from "../images/invites";
import * as leaders from "../images/leaders";
import * as monsters from "../images/monsters";
import * as slider from "../images/slider";
import * as skins from "../images/skins";
import * as avatars from "../images/avatars/dragons";

import { dailyData, dailyListData } from "../store/daily";

import WebApp from "@twa-dev/sdk";
import { friendsData } from "../store/friends";
import { levelsData } from "../store/levels";
import logo from "../images/mosnter.jpeg";
import { miningData } from "../store/mining";
import { preload } from "./preload";
import { profileData } from "../store/profile";
import { tasksData } from "../store/tasks";
import {boostListData} from "../store/boosts";
import {userSkinListData} from "../store/shop";
import {client} from "./fetch";
import {retrieveLaunchParams} from "@tma.js/sdk";

const ready = new Promise<void>((resolve) => {
  addEventListener('load', () => resolve());
  setTimeout(() => resolve(), 1000);
});

const app = document.getElementById('app')!;
WebApp.setHeaderColor('#090A0E');
WebApp.setBackgroundColor('#090A0E');

export function resize() {
  document.body.style.height = outerHeight + 'px';
  document.body.style.marginTop = 100 + 'px';
  document.body.style.paddingBottom = 100 + 'px';
  document.body.style.overflow = 'hidden';
  app.style.height = 0 + 'px';
  app.style.height = WebApp.viewportStableHeight + 'px';
  scrollTo(0, 100);
}

addEventListener('resize', resize);

export async function init() {
  WebApp.expand();

  const hasToken = !!localStorage.getItem('token')
  const { accessToken } = await client.api.auth.login.post({
    authorization: `tma ${retrieveLaunchParams().initDataRaw}`
  }).then(({ data }) => data)

  localStorage.setItem('token', accessToken)
  if (!hasToken) {
    window.location.reload()
  }

  await ready;

  const [ boost ] = await Promise.all([
    boostListData,
    profileData,
    miningData,
    levelsData,
    friendsData,
    tasksData,
    dailyData,
    dailyListData,
    userSkinListData,
    preload(gifs),
    preload(slider),
    preload(logo),
    preload(monsters),
    preload(banners),
    preload(invites),
    preload(icons),
    preload(leaders),
    preload(skins),
    preload(avatars),
  ]);

  const list = boost.boosts.map(({ url }) => url).filter(Boolean)
  const loadBoostImages = async () => {
    try {
      await preload(list)
    } catch (e) {
      console.log('error in preload');
    }
  }
  loadBoostImages()

  document.getElementById("loader")?.remove();
  document.body.onscroll = () => scrollTo(0, 100);
  resize();
}

setInterval(resize, 50);