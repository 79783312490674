<script lang="ts">
  import Gif from "./Gif.svelte";
  import { mining, updateMining } from "../store/mining";
  import { onFrame } from "../hooks/onFrame";
  import { client } from "../library/fetch";
  import { Time } from "../library/Time";
  import Flex from "./Flex.svelte";
  import Balance, { format } from "../uikit/Balance.svelte";
  import WebApp from "@twa-dev/sdk";
  import { buildEffect } from "../library/buildEffect";

  let local = new Date();

  $: status = $mining.current;
  $: deltatime = +$mining.local - +$mining.time;
  $: localtime = +local;
  $: start = +(status?.created ?? 0) + deltatime;
  $: expires = +(status?.expires ?? 0) + deltatime;
  $: fullReward = status?.reward ?? 0;
  $: persent = status ? (localtime - start) / (expires - start) : 0;
  $: reward = expires > localtime ? (fullReward * persent) | 0 : fullReward;

  onFrame(() => {
    local = new Date();
  });

  function startMining() {
    client.api.game.mining.start
      .get()
      .then(updateMining)
      .then(() => WebApp.HapticFeedback.notificationOccurred("warning"));
  }

  function claimMining() {
    client.api.game.mining.claim
      .get()
      .then((mining) => {
        if (!mining.data?.current) throw new Error("No mining");
        return Number(mining.data.current.reward);
      })
      .then(buildEffect.reward)
      .then(updateMining)
      .catch(buildEffect.error);
  }
</script>

<div>
  {#if !status}
    <button class="primary" on:mousedown={startMining}>
      <Gif type="rocket" size={28} />
      Start Mining RXP
    </button>
  {:else if +expires < localtime}
    <button class="primary" on:mousedown={claimMining}>
      <Gif type="confeti" size={28} />
      Claim
      <span class="stick claim">
        <Balance balance={reward} symbol big />
        RXP
      </span>
    </button>
  {:else}
    <button class="mining" disabled>
      <div class="persent" style="width: {persent * 100}%" />
      <Flex align="center" gap={4}>
        <Gif type="gear" size={20} />
        Mining...
      </Flex>
      <span class="time">
        {Time.format(expires - localtime)}
      </span>
      <span style="flex-grow: 1;" />
      <span class="stick">
        <span class="show">
          <Balance balance={reward} symbol big />
          RXP
        </span>
        <span class="size">+{format(reward)} RXP</span>
      </span>
    </button>
  {/if}
</div>

<style lang="sass">
  div
    flex-grow: 1
    display: flex
    flex-direction: column
    width: 100%
    justify-content: flex-end
    backdrop-filter: blur(5px)

  .mining
    justify-content: space-between
    border-color: var(--gray-dark)
    color: var(--white)
    font-size: 12px
    position: relative
    overflow: hidden
    gap: 12px

    .persent
      position: absolute
      top: 0
      bottom: 0
      left: 0
      background-color: #fff
      opacity: .1
      z-index: -1

  .time
    color: var(--gray-dark)

  .stick
    display: inline-block
    height: 36px
    line-height: 36px
    padding: 0px 12px
    border-radius: 22px
    background-color: #313238
    color: white
    position: relative
    
    .show
      position: absolute
      inset: 0

    .size
      font-family: monospace
      color: transparent

    &.claim
      background-color: var(--strong-up)
</style>
