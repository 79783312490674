<script lang="ts">
    import statsBg from '../images/stats_bg.png'
    import {profile} from "../../../store/profile";
    import Balance from "../../../uikit/Balance.svelte";
    import { friends } from "../../../store/friends";
    import dayjs from "dayjs";
    import duration from 'dayjs/plugin/duration'

    dayjs.extend(duration);

    $: experience = $profile?.experience
    $: remainingNextLevel = $profile!.level.nextUserLevel
        ? $profile!.level.nextUserLevel.from - $profile!.experience
        : 0;
    $: invites = $friends.totalCount
    $: skin = $profile.level.currentUserSkin.id
    $: improves = $profile.boosts.length
    $: daysRegister = dayjs.duration(dayjs().diff(Number($profile.createdAt))).asDays().toFixed(0)
</script>

<div class="card" style="background-image: url({statsBg})">
    <div class="item">
        <span>XP to next level:</span>
        <span>
            <Balance balance={remainingNextLevel} big />
        </span>
    </div>
    <div class="item">
        <span>Total XP:</span>
        <span>
            <Balance balance={experience} big />
        </span>
    </div>
    <div class="item">
        <span>Invite Frens:</span>
        <span>{invites}</span>
    </div>
    <div class="item">
        <span>Improves:</span>
        <span>{improves}</span>
    </div>
    <div class="item">
        <span>Days in Game:</span>
        <span>{daysRegister}</span>
    </div>
    <div class="item">
        <span>My Skin:</span>
        <span>#{skin}</span>
    </div>
</div>

<style>
    .card {
        padding: 16px 12px;
        border-radius: 20px;
        border: 1px solid #313238;
        display: flex;
        flex-direction: column;
        gap: 12px;
        object-fit: cover;
        background-repeat: no-repeat;
    }

    .item {
        font-size: 13px;
        font-weight: 600;
        line-height: 120%;
        display: flex;
        justify-content: space-between;
    }
</style>