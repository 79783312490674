import type { App } from "../../../backend/src";
import { retrieveLaunchParams } from "@tma.js/sdk";
import { treaty } from "@elysiajs/eden";

try {
  retrieveLaunchParams();
} catch (e) {
  console.log('Blablacar');
}

// Wrapper with measurement of request time
const fetchWrapper = async (input: RequestInfo | URL, init?: RequestInit) => {
  const start = Date.now();
  const pathWithoutDomain = input.toString().replace(location.origin, '');
  const response = await fetch(input, init);
  const end = Date.now();
  const delta = end - start;
  const MAX_REQUEST_TIME = 500;
  if (delta > MAX_REQUEST_TIME) {
    console.warn(`[DEBUG] [TOO LONG] "${pathWithoutDomain}" took`, delta, 'ms');
  } else {
    console.debug(`[DEBUG] "${pathWithoutDomain}" took`, delta, 'ms');
  }
  return response;
};

export const client = treaty<App>(location.origin, {
  headers: {
    authorization: localStorage.getItem('token')
  },
  fetcher: fetchWrapper
});
