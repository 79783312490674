<script context="module" lang="ts">
    export interface SkinData {
        description: string;
    }

    const SKIN_DATA: Record<number, SkinData> = {
        8: {
            description: 'Unlimited x3 Mining Boost\n' +
                '1h Mining from 500mln',
        },
        9: {
            description: 'Unlimited x4 Mining Boost\n' +
                '1h Mining from 1.5bln',
        },
        10: {
            description: 'Unlimited x5 Mining Boost\n' +
                '1h Mining from 3bln',
        },
    }
</script>

<script lang="ts">
    import Flex from "../../components/Flex.svelte";
    import Card from "./components/Card.svelte";
    import HeaderNew from "../../components/HeaderNew.svelte";
    import {userSkinList} from "../../store/shop";
    import {generateSkinPurchaseUrl} from "../../store/shop";

    async function handleBuySkin(skinId: number) {
        const skinPurchaseUrl = await generateSkinPurchaseUrl(skinId);
        if (skinPurchaseUrl && window) {
            window.open(skinPurchaseUrl);
        }
    }
</script>

<HeaderNew withMargin/>
<h1>
    Upgrade to <span>VIP</span> Skin
</h1>
<Flex full column padding="16px" gap={16}>
    {#each $userSkinList as skin}
        {#if skin.price}
            <Card
                    skin={skin}
                    skinData={SKIN_DATA[skin.id]}
                    onBuy={async () => await handleBuySkin(skin.id)}
            />
        {/if}
    {/each}
</Flex>

<style>
    span {
        color: #8702E0;
    }
</style>