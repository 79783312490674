<script lang="ts">
  import Flex from "../../components/Flex.svelte";
  import HeaderNew from "../../components/HeaderNew.svelte";
  import Carousel from "../../uikit/Carousel.svelte";
  import Gif from "../../components/Gif.svelte";
</script>

<Flex full column gap={16} align="center" justify="center" padding="16px">
  <Gif type="drum" size={89} />
  <h1>Soon</h1>
</Flex>


<!--<Flex full column gap={16} padding="0 16px" className="listing">-->
<!--  <HeaderNew disablePadding />-->

<!--  <h1>-->
<!--    Whitelist for Guarant <span>$RAX</span> Pre-Sale-->
<!--  </h1>-->

<!--  <div class="block" data-step="1">-->
<!--    <h4><span>Step 3.</span> Enter Your Wallet Address</h4>-->

<!--  </div>-->

<!--  <button class="primary" disabled>-->
<!--    Buy Whitelist for 100k 💎-->
<!--  </button>-->
<!--</Flex>-->

<style lang="scss">
  h1 > span {
    background: var(--Complement-Strong, linear-gradient(119deg, #587BFF 15.67%, #B518FF 91.74%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #AEB0B7;

    & > span {
      color: #FFF;
    }
  }

  .block {
    padding: 16px;
    border-radius: 20px;
    border-top: 1px solid var(--Faint-Weak, #313238);
    border-bottom: 1px solid var(--Faint-Weak, #313238);
    background: var(--Base-Container-Dark, #14151B);
    height: 100%;
  }

  button {
    height: 86px;
    margin-bottom: 16px;
  }
</style>