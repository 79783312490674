<script lang="ts" context="module">
  import { Signal } from "@preact/signals-core";
  export const openTaskEvents =
    events<
      [
        task?: typeof tasks extends Signal<infer A>
          ? A extends Array<infer B>
            ? B
            : A
          : undefined,
      ]
    >();
</script>

<script lang="ts">
  import { buildEffect } from "../library/buildEffect";
  import { client } from "../library/fetch";
  import { tasks, updateTasks } from "../store/tasks";
  import { profile } from "../store/profile";
  import Balance from "../uikit/Balance.svelte";
  import Flex from "./Flex.svelte";
  import Gif from "./Gif.svelte";
  import Modal from "./Modal.svelte";
  import { events } from "../library/events";
  import { onEffect } from "../hooks/onEffect";
  import SolidIcon from "./SolidIcon.svelte";

  let openTask = $tasks?.find((e) => false);

  $: actions = {
    [":invite"]() {
      window.open(`https://t.me/share/url?url=${$profile?.inviteLink}`);
    },
  } as { [key: string]: (task: typeof openTask) => void };

  onEffect(() => {
    return openTaskEvents.sub((task) => {
      openTask = task || undefined;
    });
  });
</script>

<slot />

{#if openTask}
  <Modal on:close={() => (openTask = undefined)}>
    <Flex column align="center" gap={20}>
      <SolidIcon {...openTask.task} big />
      <h2>{openTask.task.title}</h2>
      <button
        class="gradient"
        on:mousedown={() => {
          const action = actions[openTask?.task.linkUrl ?? ""];
          if (action) action(openTask);
          else window.open(openTask?.task.linkUrl ?? "");
        }}
      >
        {openTask.task.linkText}
      </button>
      <h3>and then</h3>
      <button
        class={!openTask.complate ? "primary" : ""}
        disabled={openTask.complate}
        on:mousedown={() => {
          const reward = openTask?.task.reward ?? 0;
          client.api.game.task.claim
            .get({ query: { id: openTask?.task.id ?? "" } })
            .then(({ data: task, error }) => {
              if (!task) throw error;
              openTask = undefined;
              return reward;
            })
            .then(buildEffect.reward)
            .then(updateTasks)
            .catch(buildEffect.error);
        }}
      >
        {#if !openTask.complate}
          Claim
          <div class="chip bg">
            <Balance balance={openTask.task.reward} symbol />
            <Gif type="gem" size={15} />
          </div>
        {:else}
          Claimed
        {/if}
      </button>
    </Flex>
  </Modal>
{/if}
