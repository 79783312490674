<script lang="ts" context="module">
  function portal<T extends HTMLElement>(el: HTMLDivElement, target: T | null) {
    function update(target: T | null) {
      if (target) {
        target.appendChild(el);
        el.hidden = false;
        return;
      }

      el.hidden = true;
    }

    function destroy() {
      el.hidden = true;
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      }
    }

    update(target);

    return { update, destroy };
  }
</script>

<script lang="ts" generics="T extends HTMLElement">
  export let target: T | null = null;
</script>

<div use:portal={target} hidden>
  <slot />
</div>

<style lang="sass">
  div
    display: contents
</style>
