<script lang="ts">
  import { fly } from "svelte/transition";
  import Balance from "../uikit/Balance.svelte";
  import Flex from "./Flex.svelte";
  import Gif from "./Gif.svelte";
  import { profile, updateProfile } from "../store/profile";
  import { client } from "../library/fetch";
  import { buildEffect } from "../library/buildEffect";
  import { base, premium } from "../images/invites";

  $: claim = $profile?.ammountFriends ?? 0;

  function claimFriends() {
    client.api.game.friends.claim
      .get()
      .then(({ data }) => {
        if (!data?.balance) throw new Error("No claim");
        return data.balance;
      })
      .then(buildEffect.reward)
      .finally(updateProfile)
      .catch(buildEffect.error);
  }
</script>

<Flex column fullwidth gap={16}>
  <div class="invite">
    <img src={base} alt="base" height="100%" />
    <Flex column gap={4} align="start">
      <p class="title">Invite Friend</p>
      <p class="description">
        <b><Balance balance={$profile?.invites.base ?? 0} big symbol /></b>
        <Gif type="gem" size={18} />
        for you and your friend.
      </p>
    </Flex>
  </div>
  <div class="invite">
    <img src={premium} alt="premium" height="100%" />
    <Flex column gap={4} align="start">
      <p class="title">Invite Friend with <b>TG Premium</b></p>
      <p class="description">
        <b><Balance balance={$profile?.invites.premium ?? 0} symbol /></b>
        <Gif type="gem" size={18} />
        for you and your friend.
      </p>
    </Flex>
  </div>
</Flex>

<Flex column fullwidth gap={16}>
  <h2>3 lvl Referral Program</h2>
  <p>You recieve an additional profit of your friends' income.</p>
  {#if claim}
    <button in:fly out:fly class="gradient" on:click={claimFriends}>
      <Gif type="confeti" size={32} />
      claim
      <span class="chip dark">
        <Balance balance={claim} symbol />
        <Gif type="gem" size={15} />
      </span>
    </button>
  {/if}

  <div class="blocks">
    <div class="block">
      <span class="h0">25<i class="h3">%</i></span>
      <span class="body"> For <b>direct</b> referrals </span>
    </div>
    <div class="block">
      <span class="h0">5<i class="h3">%</i></span>
      <span class="body"> For <b>2nd</b> line referrals </span>
    </div>
    <div class="block">
      <span class="h0">2<i class="h3">%</i></span>
      <span class="body">For <b>3nd</b> line referrals</span>
    </div>
  </div>
</Flex>

<style lang="sass">
  .invite
    display: flex
    padding: 7.5px 16px
    border: 1px solid #313238
    border-radius: 20px
    align-items: center
    gap: 12px

    img
      height: 48px

    .title
      color: #fff
      font-size: 15px
      font-weight: 600
      line-height: 18px

      b
        color: #467AFF

    .description
      font-size: 12px
      font-weight: 500
      line-height: 16.8px


  .blocks
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    gap: 8px

    .block
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      gap: 8px
      padding: 20px 0px
      border: 1px solid #313238
      border-radius: 20px

      .h0
        color: #587BFF
</style>
