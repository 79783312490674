<script lang="ts" context="module">
  export const dailyEvents = events<[open?: boolean]>();
</script>

<script lang="ts">
  import {
    currentDaily,
    daily,
    dailyList,
    updateDaily,
    updateListDaily,
  } from "../store/daily";
  import { client } from "../library/fetch";
  import { buildEffect } from "../library/buildEffect";
  import Modal from "./Modal.svelte";
  import { events } from "../library/events";
  import { onEffect } from "../hooks/onEffect";
  import Flex from "./Flex.svelte";
  import Gif from "./Gif.svelte";
  import Balance, { formatBig } from "../uikit/Balance.svelte";
  import Icon from "./Icon.svelte";
  import { groupBy } from "../library/groupBy";

  let open = false;

  function claim() {
    client.api.game.daily.claim
      .get()
      .then(({ data: daily }) => {
        if (!daily) throw new Error("No claim");
        return daily.reward;
      })
      .then(buildEffect.reward)
      .then(updateDaily)
      .then(updateListDaily)
      .catch(buildEffect.error);
  }

  $: isClaim = $daily;

  $: list = $dailyList ?? [];
  $: days = groupBy(list, (e) => e.group);
  $: next = $currentDaily;

  $: console.log(days);

  onEffect(() => {
    return dailyEvents.sub((value = true) => {
      open = value;
    });
  });

  $: if (open) {
    updateDaily();
    updateListDaily();
  }
</script>

<slot />

{#if open}
  <Modal on:close={() => (open = false)}>
    <Flex column fullwidth gap={20}>
      <Flex column align="center">
        <Icon icon="calendar" size={100} />
        <h1>Daily Reward</h1>
      </Flex>
      <p>
        Collect coins for daily logging game without skipping. "Claim" button
        need to press daily, otherwise the day counter will start again.
      </p>
      <Flex column fullwidth gap={8}>
        {#each days as [group, _days] (group)}
          <div
            class="row"
            style="justify-content: flex-{_days.length > 2
              ? 'start'
              : 'between'};"
          >
            {#each _days as day}
              <div
                class="day {day.claimed && 'claimed'} {next?.day === day.day &&
                  'current'}"
                style="flex-grow: {_days.length > 2 ? 0 : 1};"
              >
                <span class="info">Day {day.day}</span>
                <Gif type="gem" size={24} />
                <span>{formatBig(day.reward)}</span>
              </div>
            {/each}
          </div>
        {/each}
      </Flex>
      <button on:mousedown={claim} class="primary" disabled={!isClaim}>
        {#if isClaim}
          Claim
          <span class="chip bg">
            <Balance balance={isClaim.reward} symbol />
            <Gif type="gem" size={15} />
          </span>
        {:else}
          Return tomorrow for claim
        {/if}
      </button>
    </Flex>
  </Modal>
{/if}

<style lang="sass">
  button
    position: sticky
    bottom: -30px

    &:disabled
      background-color: var(--background)

  .row
    display: flex
    flex-wrap: wrap
    gap: 8px

  .day
    display: flex
    flex-direction: column
    align-items: center
    padding: 12px 0px
    width: calc(25% - 8px)
    flex-shrink: 0
    background-color: #1A1B22
    border-radius: 16px
    gap: 4px

    &.claimed
      background-color: #313238

    &.current
      border: 1px solid var(--gray-light)
      
  .info
    font-size: 12px
    font-weight: 600
    line-height: 16.8px
    color: var(--gray-light)
</style>
