<script lang="ts">
  import Frens from "../components/Frens.svelte";
  import Flex from "../components/Flex.svelte";
  import Gif from "../components/Gif.svelte";
  import Invite from "../components/Invite.svelte";

  import { profile } from "../store/profile";
  import Levels from "../components/Levels.svelte";
  import Leaderboard from "../components/Leaderboard.svelte";
  import Referal from "../components/Referal.svelte";
  import { Tabs, TabsItem } from "../components/Tabs";
  import TabsView from "../components/Tabs/TabsView.svelte";
  import HeaderNew from "../components/HeaderNew.svelte";
</script>

<HeaderNew withMargin />
<Tabs>
  <Flex full column>
    <Flex
      column
      fullwidth
      grow
      align="center"
      gap={20}
      padding="20px 16px 0px 16px"
    >
      <Flex column gap={12} align="center">
        <Gif type="alian" size={72} />
        <h1>
          Invite Frens — Get <Gif type="gem" />
        </h1>
        <p>
          Score {$profile?.referals.first}% of your buddies + extra {$profile
            ?.referals.second}% from their referrals and additional lvl up
          Bonus.
        </p>

        <div class="group button secondary">
          <TabsItem title="Frens">
            <Referal />
            <Frens />
<!--            <Levels />-->
          </TabsItem>

          <TabsItem title="Leaderboard">
            <Leaderboard />
          </TabsItem>
        </div>
      </Flex>
      <TabsView />
    </Flex>
    <Invite />
  </Flex>
</Tabs>
