<script lang="ts">
  import Flex from "../../../components/Flex.svelte";
  import Mining from "../../../components/Mining.svelte";
  import * as monsters from "../../../images/monsters";
  import * as skins from "../../../images/skins";
  import StatInfo from "./StatInfo.svelte";
  import GifGem from "../../../uikit/GifGem.svelte";
  import Balance from "../../../uikit/Balance.svelte";
  import {profile} from "../../../store/profile";
  import {SKINS} from "../../../config";

  $: balance = $profile!.balance;
  $: skin = Number($profile!.level.currentUserSkinIndex + 1) as keyof typeof SKINS;
</script>

<Flex column full>
  <Flex justify="center" gap={8} align="center">
      <GifGem size={30} />
    <h1>
      <Balance {balance} big />
    </h1>
  </Flex>

  <div class="game" style="background-image: url({SKINS[skin]?.skin});" />

  <Flex column gap={12} fullwidth padding="0px 16px" className="block">
    <div class="btn stat">
      <StatInfo />
    </div>

    <div class="btn">
      <Mining />
    </div>
  </Flex>
</Flex>

<style>
  .game {
    flex-grow: 1;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    /*border-radius: 20px 20px 0 0;*/
  }

  :global(.block) {
    position: relative;
  }

  .stat {
    position: absolute;
    top: -100%;
    left: 16px;
    right: 16px;
  }
</style>
