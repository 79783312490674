<script lang="ts">
  import Flex from "../components/Flex.svelte";
  import { TabsView, Tabs } from "../components/Tabs";

  let content: HTMLDivElement | null = null;
</script>

<Tabs on:change={() => setTimeout(() => content?.scrollTo(0, 0))}>
  <Flex full column>
    <div class="content" bind:this={content}>
      <TabsView />
    </div>
    <div class="menu">
      <slot />
    </div>
  </Flex>
</Tabs>

<style lang="sass">
  .content
    flex-grow: 1
    overflow: auto
    position: relative

  .menu
    display: flex
    justify-content: space-around

</style>
