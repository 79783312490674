<script lang="ts">
  import HeaderNew from "../../components/HeaderNew.svelte";
  import Card from "./components/Card.svelte";
  import Menu from "./components/Menu.svelte";
  import Modal from "./components/Modal.svelte";
  import {boostList} from "../../store/boosts";
  import {profile} from "../../store/profile";
  import type {UserBoostModel} from "../../../../backend/src/models/UserBoostModel";
  import type {UserBoostsClient} from "../../../../backend/src/config/definitions";

  const myBoost = new Map<number, UserBoostModel & UserBoostsClient>()
  const categoryList = new Map<number, string>()
  $: for (const b of $profile?.boosts) {
      myBoost.set(b.boostId, b)
  }
  $boostList?.boosts.map(({ category, categoryId }) => {
    categoryList.set(categoryId, category)
  })
  const category = Array.from(categoryList).sort((a, b) => a[0] - b[0])

  $: currentCategory = category[0][0]
  $: currentIdCard = null as null | number
  $: currentCard = $boostList?.boosts.find(({ id }) => id === currentIdCard)

  const handlerCategory = (index: number) => {
    currentCategory = index
  }

  const handlerCard = (id: number | null) => {
    currentIdCard = id
  }

</script>

<HeaderNew withMargin />
<div class="root">
  <h1>
    Boost 💸 Mining
  </h1>

  <Menu
          index={currentCategory}
          on:change={(e) => handlerCategory(e.detail)}
          data={category.map(([index, name]) => ({ index, name }))}
  />

<!--  <Carousel-->
<!--          startIndex={currentCategory - 1}-->
<!--          loop={false}-->
<!--          dots={false}-->
<!--          perPage={1}-->
<!--          on:change={({ detail }) => handlerCategory(detail.currentSlide + 1)}-->
<!--  >-->
<!--    {#each category ?? [] as [id], idx}-->
      <div class={$boostList?.boosts.find(({ categoryId }) => categoryId === currentCategory)?.variant || 'block'}>
<!--        <VariableSizeGrid-->
<!--                columnCount={1000}-->
<!--                height={window.innerHeight * (1 - 40/100)}-->
<!--                rowCount={6}-->
<!--                rowHeight={()=>95}-->
<!--                width="100%"-->
<!--                let:items-->
<!--        >-->
          {#each $boostList?.boosts.filter(({ categoryId }) => categoryId === currentCategory) as boost}
            <Card
                    icon={boost.url}
                    name={boost.name}
                    variant={boost?.variant || 'block'}
                    id={boost.id}
                    price={myBoost.get(boost.id)?.boostNextLevelPrice || boost.price}
                    boost={myBoost.get(boost.id)?.boostBonus || boost.bonus}
                    lvl={myBoost.get(boost.id)?.boostLevel || 0}
                    needLvl={$profile?.level.currentUserLevel.id < boost.opensAtLevel && boost.opensAtLevel}
                    on:open={(e) => handlerCard(e.detail)}
            />
          {/each}
<!--        </VariableSizeGrid>-->
      </div>
    <!--{/each}-->
<!--  </Carousel>-->
</div>

{#if currentIdCard}
  <Modal
          id={currentIdCard}
          icon={currentCard?.url}
          name={currentCard?.name}
          description={currentCard?.description}
          price={myBoost.get(currentIdCard)?.boostNextLevelPrice || currentCard.price}
          currentBoost={myBoost.get(currentIdCard)?.boostBonus || 0}
          nextBoost={myBoost.get(currentIdCard)?.boostNextLevelBonus || currentCard.bonus}
          lvl={myBoost.get(currentIdCard)?.boostLevel || 0}
          variant={currentCard?.variant || 'block'}
          on:close={() => handlerCard(null)}
  />
{/if}

<style>
    .root {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 16px;
    }

  .block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .line {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>